interface InputProps extends React.HTMLProps<HTMLInputElement> {
  className?: string;
  parentClassName?: string;
}

export function SearchField({
  className = "",
  parentClassName = "",
  placeholder = "Search...",
  ...props
}: InputProps) {
  return (
    <div className={`relative ${parentClassName}`}>
      <input
        {...props}
        className={`w-full h-10 py-2 px-8 rounded-lg outline-none border-cs-1 border-[#CFDBD5] text-black hover:border-primary focus:border-primary bg-transparent text-xs placeholder-black placeholder:font-normal`}
        placeholder={placeholder}
      />

      <svg
        className={`absolute top-1/2 -translate-y-1/2 transition-all left-4`}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.21875 6.50134C2.80208 7.08468 3.51042 7.37634 4.34375 7.37634C5.17708 7.37634 5.88542 7.08468 6.46875 6.50134C7.05208 5.91801 7.34375 5.20968 7.34375 4.37634C7.34375 3.54301 7.05208 2.83468 6.46875 2.25134C5.88542 1.66801 5.17708 1.37634 4.34375 1.37634C3.51042 1.37634 2.80208 1.66801 2.21875 2.25134C1.63542 2.83468 1.34375 3.54301 1.34375 4.37634C1.34375 5.20968 1.63542 5.91801 2.21875 6.50134ZM8.34375 7.37634L11.6562 10.6888L10.6562 11.6888L7.34375 8.37634V7.84509L7.15625 7.65759C6.36458 8.34509 5.42708 8.68884 4.34375 8.68884C3.13542 8.68884 2.10417 8.27218 1.25 7.43884C0.416667 6.60551 0 5.58468 0 4.37634C0 3.16801 0.416667 2.14718 1.25 1.31384C2.10417 0.459676 3.13542 0.0325928 4.34375 0.0325928C5.55208 0.0325928 6.57292 0.459676 7.40625 1.31384C8.23958 2.14718 8.65625 3.16801 8.65625 4.37634C8.65625 4.81384 8.55208 5.31384 8.34375 5.87634C8.13542 6.41801 7.89583 6.85551 7.625 7.18884L7.8125 7.37634H8.34375Z"
          fill="black"
        />
      </svg>
    </div>
  );
}
