import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
} from "react-table";
import { useSearchParams } from "react-router-dom";
import Table from "../../../lib/react-table/Table";
import Footer from "../../../components/global/amp/Footer";
import moment from "moment";
import { apiSlice } from "../../../store/apiSlice";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { SearchField } from "../../../components/global/SearchField";
import { Button } from "../../../components/global/Button";
import { TableTextNormal } from "../../../components/global/TableTextNormal";
import Select from "react-select";
import SingleSelectOption from "../../../lib/react-select/SingleSelectOption";
import { DropDownIndicator } from "../attractions/AttractionOpeningTimes";
import { components } from "react-select";

interface PricingSelection {
  name: string;
  pricingOptionId: string;
  quantity: number;
  price: string;
}
interface TicketReportingBooking {
  id: string;
  ticket_id: string;
  email: string;
  venue_id: string;
  venue: AttractionDetails;
  created_at: string;
  updated_at: string;
  total_cost: number;
  customer_details: {
    firstName: string;
    lastName: string;
    email: string;
  };
  booking_status: string;
  booking_reference: string;
  booking_details: {
    date: string;
    pricingOptionSelection: {
      selections: PricingSelection[];
    };
  };
  ticket: TicketReportingTickets;
}

interface DOWTKTicketingProduct {
  id: string;
  venue_id: string;
  name: string;
  description: string;
  status: string;
  created_at: string;
  updated_at: string;
  cancellation_policy: string;
  sent_reminder: string | null;
  tickets: {
    id: string;
    category: string;
    name: string;
    description: string;
    default_price: string;
    default_price_formatted: string;
    default_price_currency: string;
    status: string;
    created_at: string;
    updated_at: string;
    product_id: string;
    min_age: number;
    max_age: number;
    metadata: Record<string, any> | null;
    uses_external_ticket_codes: boolean;
    capacity: number;
    only_valid_with_options: any | null;
  }[];
  periods: {
    id: string;
    start_date: string;
    end_date: string;
    name: string;
    monday_availability_id: string | null;
    tuesday_availability_id: string | null;
    wednesday_availability_id: string | null;
    thursday_availability_id: string | null;
    friday_availability_id: string | null;
    saturday_availability_id: string | null;
    sunday_availability_id: string | null;
    created_at: string;
    updated_at: string;
    product_id: string;
  }[];
}

interface TicketReportingTickets {
  id: string;
  provider: string;
  dowtkTicketingProducts: DOWTKTicketingProduct;
}

interface TicketingData extends TicketReportingBooking {
  currentTicket: PricingSelection;
}

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

export function AdminTicketingReporting() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [getQueryPageSize, setQueryPageSize] = useState<string>("25");
  const [getQueryPage, setQueryPage] = useState<string>(
    searchParams.get("page")
      ? parseInt(searchParams?.get("page") ?? "0").toString()
      : "0"
  );
  const [pageType, setPageType] = useState<"tickets" | "bookings">(
    (searchParams.get("pageType") as "tickets" | "bookings") || "bookings"
  );
  const [getQuerySortBy, setQuerySortBy] = useState<string>("name");
  const [getQuerySearchQuery, setQuerySearchQuery] = useState<string>("");
  const [getQuerySortDesc, setQuerySortDesc] = useState<string>("false");
  const [getFilter, setFilter] = useState<
    "all" | "attraction-product" | "supplier"
  >("all");
  const [venueId, setVenueId] = useState("");
  const { data: venuesWithTickets } = apiSlice.useGetVenuesWithTicketsQuery();
  const venuesOptions =
    venuesWithTickets?.map((v) => ({
      value: v.id,
      label: v.name,
    })) || [];

  const providers = [
    "txgb",
    "tiqets",
    "musement",
    "digitickets",
    "experiencebank",
    "ingresso",
    "todaytix",
    "viator",
    "seetickets",
    "fever",
    "partnerize",
    "cjcom",
    "ticketmaster",
    "dowtk",
    "ventrata",
    "fusemetrix",
    "other",
  ];
  const providerOptions = providers.map((p) => ({
    value: p,
    label: p,
  }));
  const [providerFilter, setProviderFilter] = useState("");

  const [visitDateFrom, setVisitDateFrom] = useState("");
  const [visitDateTo, setVisitDateTo] = useState("");

  const [purchaseDateFrom, setPurchaseDateFrom] = useState("");
  const [purchaseDateTo, setPurchaseDateTo] = useState("");
  const prevQuerySearch = useRef(getQuerySearchQuery);
  const queryData = useMemo(
    () => ({
      pageSize: "25",
      page:
        prevQuerySearch.current !== getQuerySearchQuery ? "0" : getQueryPage,
      sortBy: getQuerySortBy,
      query: getQuerySearchQuery,
      sortDesc: getQuerySortDesc,
      filter: getFilter,
      venue_id: venueId || "",
      provider: providerFilter || "",
      purchase_date_from: purchaseDateFrom,
      purchase_date_to: purchaseDateTo,
      visit_date_from: visitDateFrom,
      visit_date_to: visitDateTo,
    }),
    [
      prevQuerySearch,
      getQuerySearchQuery,
      getQueryPage,
      getQuerySortBy,
      getQuerySortDesc,
      getFilter,
      venueId,
      providerFilter,
      visitDateFrom,
      visitDateTo,
      purchaseDateFrom,
      purchaseDateTo,
    ]
  );

  useEffect(() => {
    setSingleTicketView('')
  }, [queryData]);
  const { data: bookingData, isFetching: isFetchingBookings } =
    apiSlice.useAdminTicketReportingBookingsQuery(queryData, {
      refetchOnMountOrArgChange: true,
    });

  const [singleTicketView, setSingleTicketView] = useState('')
  const ticketingData = useMemo<{
    results: TicketingData[];
    total: number;
  }>(() => {
    const tickets =
      (bookingData?.results as TicketReportingBooking[])?.filter(b => {
        if (singleTicketView) {
          if (b.id !== singleTicketView) {
            return false
          }
        }
        return true
      })
        ?.map(
          (original) =>
            original.booking_details?.pricingOptionSelection?.selections?.map(
              (t) => ({
                ...original,
                currentTicket: t,
              })
            ) || []
        )
        ?.flat() || [];

    return {
      results: tickets,
      total: tickets.length * (Math.ceil((bookingData?.total || 0) / 25) || 1),
    };
  }, [bookingData, singleTicketView]);

  const tableData = React.useMemo<{ results: any[]; total: number }>(
    () =>
      (pageType === "tickets" ? ticketingData : bookingData) || {
        total: 0,
        results: [],
      },
    [bookingData, ticketingData, pageType]
  );
  
  const columns = React.useMemo<Column<any>[]>(
    () =>
      pageType === "bookings"
        ? [
            {
              Header: "Booking ID",
              accessor: "booking_refrence",
              Cell: ({
                row: { original },
              }: CellProps<TicketReportingBooking>) => {
                return (
                  <TableTextNormal className="text-center">
                    {original.booking_reference || "-"}
                  </TableTextNormal>
                );
              },
              width: "10%",
              disableSortBy: true,
            },

            {
              Header: "Customer Name",
              accessor: "customer_details",
              Cell: ({
                row: { original },
              }: CellProps<TicketReportingBooking>) => {
                return (
                  <TableTextNormal className="text-center">
                    {original.customer_details?.firstName}{" "}
                    {original.customer_details?.lastName}
                  </TableTextNormal>
                );
              },
              width: "10%",
              disableSortBy: true,
            },

            {
              Header: "Customer Email",
              accessor: "customer_details.email",
              Cell: ({
                row: { original },
              }: CellProps<TicketReportingBooking>) => {
                return (
                  <TableTextNormal className="text-center">
                    {original.customer_details?.email || "-"}
                  </TableTextNormal>
                );
              },
              width: "10%",
              disableSortBy: true,
            },

            {
              Header: "Attraction / Product",
              accessor: "attraction",
              Cell: ({
                row: { original },
              }: CellProps<TicketReportingBooking>) => {
                return (
                  <TableTextNormal className="text-center">
                    {original.venue?.name}
                  </TableTextNormal>
                );
              },
              width: "10%",
              disableSortBy: true,
            },

            {
              Header: "Purchase Date",
              accessor: "purchase_date",
              Cell: ({
                row: { original },
              }: CellProps<TicketReportingBooking>) => {
                return (
                  <TableTextNormal className="text-center">
                    {moment(original.created_at).format("DD MMM YYYY")}
                  </TableTextNormal>
                );
              },
              width: "10%",
            },

            {
              Header: "Visit Date",
              accessor: "visit_date",
              Cell: ({
                row: { original },
              }: CellProps<TicketReportingBooking>) => {
                return (
                  <TableTextNormal className="text-center">
                    {moment(original.booking_details.date).format(
                      "DD MMM YYYY"
                    )}
                  </TableTextNormal>
                );
              },
              width: "10%",
            },

            {
              Header: "Ticket Quantity",
              accessor: "booking_details",
              Cell: ({
                row: { original },
              }: CellProps<TicketReportingBooking>) => {
                return (
                  <TableTextNormal className="text-center">
                    <button type="button" className="px-2 text-cs-pink" onClick={() => {
                      setSingleTicketView(original.id)
                      setPageType('tickets')
                    }}>
                      {original?.booking_details?.pricingOptionSelection?.selections
                        .map((s) => s.quantity)
                        .reduce((a, b) => a + b, 0) || "0"}
                    </button>
                  </TableTextNormal>
                );
              },
              width: "10%",
            },

            {
              Header: "Booking Value",
              accessor: "total_cost",
              Cell: ({
                row: { original },
              }: CellProps<TicketReportingBooking>) => {
                return (
                  <TableTextNormal className="text-center">
                    {original.total_cost}
                  </TableTextNormal>
                );
              },
              width: "10%",
            },

            {
              Header: "Supplier",
              accessor: "provider",
              Cell: ({
                row: { original },
              }: CellProps<TicketReportingBooking>) => {
                return (
                  <TableTextNormal className="text-center">
                    {capitalizeFirstLetter(original.ticket?.provider)}
                  </TableTextNormal>
                );
              },
              width: "10%",
              disableSortBy: true,
            },

            {
              Header: "Ticket Link",
              accessor: "link",
              Cell: ({
                row: { original },
              }: CellProps<TicketReportingBooking>) => {
                return (
                  <TableTextNormal className="text-center">
                    <a
                      target="_blank"
                      className="text-cs-pink"
                      href={
                        "https://www.dayoutwiththekids.co.uk" +
                        original.venue?.link
                      }
                    >
                      https://www.dayoutwiththekids.co.uk{original.venue.link}
                    </a>
                  </TableTextNormal>
                );
              },
              width: "10%",
              disableSortBy: true,
            },
          ]
        : [
            {
              Header: "Booking ID",
              accessor: "booking_refrence",
              Cell: ({ row: { original } }: CellProps<TicketingData>) => {
                return (
                  <TableTextNormal className="text-center">
                    {original.booking_reference || "-"}
                  </TableTextNormal>
                );
              },
              width: "11.11%",
              disableSortBy: true,
            },

            {
              Header: "Customer Name",
              accessor: "customer_details",
              Cell: ({ row: { original } }: CellProps<TicketingData>) => {
                return (
                  <TableTextNormal className="text-center">
                    {" "}
                    {original.customer_details?.firstName}{" "}
                    {original.customer_details?.lastName}
                  </TableTextNormal>
                );
              },
              width: "11.11%",
              disableSortBy: true,
            },

            {
              Header: "Customer Email",
              accessor: "customer_details.email",
              Cell: ({ row: { original } }: CellProps<TicketingData>) => {
                return (
                  <TableTextNormal className="text-center">
                    {" "}
                    {original.customer_details?.email || "-"}
                  </TableTextNormal>
                );
              },
              width: "11.11%",
              disableSortBy: true,
            },

            {
              Header: "Attraction / Product",
              accessor: "attraction",
              Cell: ({ row: { original } }: CellProps<TicketingData>) => {
                return (
                  <TableTextNormal className="text-center">
                    {original.venue?.name || "-"}
                  </TableTextNormal>
                );
              },
              width: "11.11%",
              disableSortBy: true,
            },

            {
              Header: "Purchase Date",
              accessor: "purchase_date",
              Cell: ({ row: { original } }: CellProps<TicketingData>) => {
                return (
                  <TableTextNormal className="text-center">
                    {" "}
                    {moment(original.created_at).format("DD MMM YYYY")}
                  </TableTextNormal>
                );
              },
              width: "11.11%",
            },

            {
              Header: "Visit Date",
              accessor: "visit_date",
              Cell: ({ row: { original } }: CellProps<TicketingData>) => {
                return (
                  <TableTextNormal className="text-center">
                    {" "}
                    {moment(original.booking_details.date).format(
                      "DD MMM YYYY"
                    )}
                  </TableTextNormal>
                );
              },
              width: "11.11%",
            },

            {
              Header: "Ticket Type",
              accessor: "ticket_type",
              Cell: ({ row: { original } }: CellProps<TicketingData>) => {
                return (
                  <TableTextNormal className="text-center">
                    {original.currentTicket?.name}
                  </TableTextNormal>
                );
              },
              disableSortBy: true,
              width: "11.11%",
            },

            {
              Header: "Ticket Price",
              accessor: "value",
              Cell: ({ row: { original } }: CellProps<TicketingData>) => {
                return (
                  <TableTextNormal className="text-center">
                    {original.currentTicket?.price}
                  </TableTextNormal>
                );
              },
              width: "11.11%",
              disableSortBy: true,
            },

            {
              Header: "Supplier",
              accessor: "provider",
              Cell: ({ row: { original } }: CellProps<TicketingData>) => {
                return (
                  <TableTextNormal className="text-center">
                    {capitalizeFirstLetter(original.ticket?.provider)}
                  </TableTextNormal>
                );
              },
              width: "11.11%",
              disableSortBy: true,
            },
          ],
    [pageType]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageSize, pageIndex, sortBy, globalFilter },
  } = useTable<any>(
    {
      columns,
      data: tableData.results,
      autoResetPage: false,
      manualPagination: true,
      manualSortBy: true,
      pageCount: tableData?.total || 0,
      disableSortRemove: true,
      manualGlobalFilter: true,
      initialState: {
        pageSize:
          parseInt(searchParams.get("pageSize")!) || parseInt(getQueryPageSize),
        pageIndex: parseInt(getQueryPage),
        globalFilter: searchParams.get("query") || getQuerySearchQuery,
        sortBy: [
          {
            id: searchParams.get("sortBy") || getQuerySortBy,
            desc:
              searchParams.get("sortDesc") === "true" ||
              getQuerySortDesc !== "false",
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<any>;

  useEffect(() => {
    if (pageType === "tickets") {
      setPageSize(ticketingData.results.length);
    } else {
      setPageSize(25);
    }
  }, [pageType, setPageSize]);

  useEffect(() => {
    document.title = "Ticket Reporting | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (getQuerySearchQuery !== prevQuerySearch.current) {
      setQueryPage("0");
      gotoPage(0);

      prevQuerySearch.current = getQuerySearchQuery;
    }
  }, [getQuerySearchQuery, gotoPage]);
  useEffect(() => {
    if (!isFetchingBookings) {
      setSearchParams(
        {
          // page: pageIndex.toString(),
          query: globalFilter ? globalFilter : "",
          sortBy: sortBy[0].id.toLowerCase(),
          sortDesc: sortBy[0].desc!.toString(),
        },
        { replace: JSON.stringify(searchParams) === "{}" }
      );

      setQueryPageSize(pageSize.toString());
      setQueryPage(pageIndex.toString());
      setQuerySearchQuery(globalFilter ? globalFilter : "");
      setQuerySortBy(sortBy[0].id.toLowerCase());
      setQuerySortDesc(sortBy[0].desc!.toString());

      prevQuerySearch.current = getQuerySearchQuery;
    } else {
    }
  }, [
    pageSize,
    pageIndex,
    globalFilter,
    isFetchingBookings,
    getQuerySearchQuery,
    searchParams,
    setSearchParams,
    sortBy,
  ]);

  const [tableId, setTableId] = useState(0);

  useEffect(() => {
    setTableId((c) => c + 1);
  }, [pageType, gotoPage, pageIndex]);

  function convertObjectToCsv(data: any) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => escapeCsvField(row[header]));
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  }

  function escapeCsvField(field: string) {
    if (typeof field === "string") {
      return `"${field.replace(/"/g, '""')}"`;
    }
    return field;
  }

  function exportCSV() {
    const csvString = convertObjectToCsv(
      tableData?.results.map((original) => {
        return pageType === "bookings"
          ? {
              "Booking ID": original.booking_reference || "-",
              "Customer Name":
                original.customer_details?.firstName +
                " " +
                original.customer_details?.lastName,
              "Customer Email": original.customer_details?.email || "-",
              "Attraction / Product": original.venue?.name,
              "Purchase Date": moment(original.created_at).format(
                "DD MMM YYYY"
              ),
              "Visit Date": moment(original.booking_details.date).format(
                "DD MMM YYYY"
              ),
              "Ticket Quantity":
                original?.booking_details?.pricingOptionSelection?.selections
                  .map((s: any) => s.quantity)
                  .reduce((a: number, b: number) => a + b, 0) || "0",
              "Booking Value": original.total_cost,
              Supplier: capitalizeFirstLetter(original.ticket?.provider),
              "Ticket Link": `https://www.dayoutwiththekids.co.uk${original.venue.link}`,
            }
          : {
              "Booking ID": original.booking_reference || "-",
              "Customer Name":
                original.customer_details?.firstName +
                " " +
                original.customer_details?.lastName,
              "Customer Email": original.customer_details?.email || "-",
              "Attraction / Product": original.venue?.name,
              "Purchase Date": moment(original.created_at).format(
                "DD MMM YYYY"
              ),
              "Visit Date": moment(original.booking_details.date).format(
                "DD MMM YYYY"
              ),
              "Ticket Type": original.currentTicket?.name,
              "Ticket Price": original.currentTicket?.price,
              Supplier: capitalizeFirstLetter(original.ticket?.provider),
            };
      })
    );
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `export-${pageType}-${new Date().toLocaleDateString()}.csv`
    );

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function debounce(fn: Function, delay = 250) {
    let timeout: NodeJS.Timeout | null = null;

    return (...args: any) => {
        clearTimeout(timeout!);
        timeout = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}
  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <DashboardHeaderContainer slim={true}>
        <h1 className="font-extrabold text-xl text-black mr-7">
          Ticketing Reports
        </h1>

        <SearchField
          parentClassName="max-w-full w-[437px] ml-auto"
          placeholder="Search by Customer Name, Customer Email and Booking ID"
          defaultValue={searchParams.get("query") ?? ""}
          onChange={(e) => {
            const { value } = e.currentTarget;

            debounce(() => {
              setGlobalFilter(value);
            }, 800)()
          }}
        />
      </DashboardHeaderContainer>

      <DashboardContainer>
        <div className="px-5 xl:px-[20px] flex flex-wrap xl:flex-row flex-col gap-y-2 justify-between items-center">
          <div className="flex flex-wrap gap-2.5 w-full">
            <Button
              color={pageType === "bookings" ? "primary" : "outlineGray"}
              scale={"md"}
              onClick={() => {
                setPageType("bookings")
                setSingleTicketView('')
              }}
            >
              Bookings
            </Button>
            <Button
              color={pageType === "tickets" ? "primary" : "outlineGray"}
              scale={"md"}
              onClick={() => setPageType("tickets")}
            >
              Tickets
            </Button>
          </div>

          <div className="flex flex-wrap gap-2.5 items-center justify-end w-full">
            <div className="inline-flex gap-2.5 items-center">
              <label className="flex items-center text-xs font-bold text-black md:whitespace-nowrap">
                Venue
              </label>
              <Select
                value={venuesOptions.find((o) => o.value === venueId)}
                isClearable={true}
                components={{
                  Option: SingleSelectOption,
                  DropdownIndicator: DropDownIndicator,
                  IndicatorSeparator: () => null,
                  ClearIndicator: (props) => (
                    <components.ClearIndicator {...props}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </components.ClearIndicator>
                  ),
                }}
                options={venuesOptions}
                isSearchable={true}
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    margin: 0,
                    border: "none",
                  }),
                  menuList: (base, isSelected) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                    background: "white",

                    "::-webkit-scrollbar": {
                      width: 14,
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#c2c2c2",
                      borderRadius: 10,
                      border: "4px solid white",
                    },
                  }),
                  option: (
                    base,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...base,
                      background: "white",
                      border: "1px solid #c2c2c2",
                      color: isSelected ? "white" : "#555",
                      "&:hover": {
                        backgroundColor: "#6836D1",
                        color: isSelected || isFocused ? "white" : "#555",
                        border: isFocused ? "1px solid white" : "",
                      },
                      "&:active": {
                        backgroundColor: "#6836D1",
                        color: "white",
                      },
                    };
                  },
                  singleValue: (base) => ({
                    ...base,
                    color: "#555",
                    fontSize: 14,
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    cursor: "pointer",
                    outline: "none",
                    border: state.isFocused
                      ? "1px solid #CFDBD5"
                      : "1px solid #CFDBD5",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #CFDBD5"
                        : "1px solid #CFDBD5",
                    },
                    boxShadow: "none",
                    paddingTop: 3,
                    paddingBottom: 3,
                    margin: 0,
                    minHeight: 40,
                    minWidth: 190,
                    borderRadius: 8,
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontSize: 14,
                  }),
                  clearIndicator: (styles) => ({
                    ...styles,
                    padding: "8px 0 !important",
                  }),
                }}
                onChange={(value) => setVenueId(value?.value as string)}
              />
            </div>

            <div className="inline-flex gap-2.5 items-center">
              <label className="flex items-center text-xs font-bold text-black md:whitespace-nowrap">
                Supplier
              </label>
              <Select
                value={providerOptions.find((o) => o.value === providerFilter)}
                isClearable={true}
                components={{
                  Option: SingleSelectOption,
                  DropdownIndicator: DropDownIndicator,
                  IndicatorSeparator: () => null,
                  ClearIndicator: (props) => (
                    <components.ClearIndicator {...props}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </components.ClearIndicator>
                  ),
                }}
                options={providerOptions}
                isSearchable={true}
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    margin: 0,
                    border: "none",
                  }),
                  menuList: (base, isSelected) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                    background: "white",

                    "::-webkit-scrollbar": {
                      width: 14,
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#c2c2c2",
                      borderRadius: 10,
                      border: "4px solid white",
                    },
                  }),
                  option: (
                    base,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...base,
                      background: "white",
                      border: "1px solid #c2c2c2",
                      color: isSelected ? "white" : "#555",
                      "&:hover": {
                        backgroundColor: "#6836D1",
                        color: isSelected || isFocused ? "white" : "#555",
                        border: isFocused ? "1px solid white" : "",
                      },
                      "&:active": {
                        backgroundColor: "#6836D1",
                        color: "white",
                      },
                    };
                  },
                  singleValue: (base) => ({
                    ...base,
                    color: "#555",
                    fontSize: 14,
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    cursor: "pointer",
                    outline: "none",
                    border: state.isFocused
                      ? "1px solid #CFDBD5"
                      : "1px solid #CFDBD5",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #CFDBD5"
                        : "1px solid #CFDBD5",
                    },
                    boxShadow: "none",
                    paddingTop: 3,
                    paddingBottom: 3,
                    margin: 0,
                    minHeight: 40,
                    minWidth: 190,
                    borderRadius: 8,
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontSize: 14,
                  }),
                  clearIndicator: (styles) => ({
                    ...styles,
                    padding: "8px 0 !important",
                  }),
                }}
                onChange={(value) => setProviderFilter(value?.value as string)}
              />
            </div>

            <div className="inline-flex gap-2.5 items-center">
              <label className="flex items-center text-xs font-bold text-black md:whitespace-nowrap">
                Purchase Date
              </label>
              <input value={purchaseDateFrom} onChange={(e) => {
                setPurchaseDateFrom(e.target.value);
                setVisitDateTo("");
                setVisitDateFrom("");
              }} className="border-cs-1 text-[#212121] rounded-lg py-2 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal  border-[#CFDBD5] hover:border-cs-pink " placeholder="From" name="purchaseDateFrom" type="date" />
              
              <input value={purchaseDateTo} onChange={(e) => {
                setPurchaseDateTo(e.target.value);
                setVisitDateTo("");
                setVisitDateFrom("");
              }} className="border-cs-1 text-[#212121] rounded-lg py-2 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal  border-[#CFDBD5] hover:border-cs-pink " placeholder="To" name="purchaseDateTo" type="date" />
            </div>

            <div className="inline-flex gap-2.5 items-center">
              <label className="flex items-center text-xs font-bold text-black md:whitespace-nowrap">
                Visit Date
              </label>
              <input value={visitDateFrom} onChange={(e) => {
                setVisitDateFrom(e.target.value);
                setPurchaseDateTo("");
                setPurchaseDateFrom("");
              }} className="border-cs-1 text-[#212121] rounded-lg py-2 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal  border-[#CFDBD5] hover:border-cs-pink " placeholder="From" name="visitDateFrom" type="date" />
              <input value={visitDateTo} onChange={(e) => {
                setVisitDateTo(e.target.value);
                setPurchaseDateTo("");
                setPurchaseDateFrom("");
              }} className="border-cs-1 text-[#212121] rounded-lg py-2 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal  border-[#CFDBD5] hover:border-cs-pink " placeholder="To" name="visitDateTo" type="date" />
            </div>

            <Button
              color="outlineGray"
              scale="md"
              className="gap-2 ml-2.5 whitespace-nowrap"
              onClick={() => {
                exportCSV();
              }}
            >
              Export CSV
            </Button>
          </div>
        </div>
        <section className="flex flex-col px-5 xl:px-[20px]">
          <div
            id="bookingsTable"
            className="mt-5 max-w-full sm:max-w-[calc(100vw-3rem)]"
          >
            <Table<any>
              key={`admin-table-${tableId}`}
              // <table id>
              tableId={`${pageType}Table`}
              // <table className>
              tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
              // <thead className>
              tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
              // <tbody className>
              tableTbodyClassNames={`relative ${
                page.length === 0 ? "h-[60px]" : ""
              }`}
              // Mobile <tbody className>
              tableTbodyMobileClassNames="block"
              // <tbody><tr className>
              tableTbodyTrClassNames="h-1 last-of-type:hidden"
              // Empty <tbody><tr className>
              tableTbodyTrEmptyMobileClassNames="border-none"
              // Mobile <tbody><tr className>
              tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative bg-white border-2 border-cs-off-white font-sm font-normal text-cs-gray rounded-xl p-3"
              // <tbody><tr><td className>
              tableTbodyTrTdClassNames="bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
              // Empty <tbody><tr><td className>
              tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
              // Mobile <tbody><tr><td className>
              tableTbodyTrEmptyTdMobileClassNames="h-2"
              // Hide Columns On Mobile
              hideColumnOnMobile={["Last Logged In", "Status", "Attractions"]}
              // react-table props
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              page={page}
              pages={bookingData?.total}
              prepareRow={prepareRow}
              pageIndex={pageIndex}
              pageSize={25}
              pageOptions={pageOptions}
              gotoPage={gotoPage}
              setPageSize={setPageSize}
              isFetching={isFetchingBookings}
              hideRowsPerPage={true}
              hidePagination={!!singleTicketView && pageType === 'tickets'}
            />
          </div>
        </section>
      </DashboardContainer>

      <Footer />
    </div>
  );
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
