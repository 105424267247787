import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Editor,
  BaseEditor,
  Descendant,
  createEditor,
  Element as SlateElement,
  Text as SlateText,
  Transforms,
} from "slate";
import {
  useSlate,
  Slate,
  Editable,
  withReact,
  ReactEditor,
  RenderLeafProps,
  useSelected,
} from "slate-react";
import { jsx } from "slate-hyperscript";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { useAppSelector } from "../../store";

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']
type CustomElement = { type: "paragraph" | 'link' | 'list'; children: CustomText[] };
type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  link?: boolean,
  href?: string
};
type SlateNode = SlateElement & CustomText;

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

interface LeafProps {
  attributes: Record<string, unknown>;
  children: ReactNode;
  leaf: any;
}

type Formats = "bold" | "italic" | "underline" | "link" | "href";

const RichTextEditor = ({
  initialValue,
  label,
  maxChars,
  isWordCount = false,
  charLabel,
  onChange,
  onDirty,
  labelClassName,
  placeholder = 'Enter some plain text...',
  maxCharSuffix = ''
}: {
  initialValue?: string;
  label?: string;
  maxChars?: number;
  isWordCount?: boolean;
  charLabel?: string;
  onChange?: Function;
  onDirty?: Function;
  labelClassName?: string,
  placeholder?: string,
  maxCharSuffix?: string
}) => {
  const { user } = useAppSelector(state => state.global)
  const [key, setKey] = useState(0);
  const [canEdit, setCanEdit] = useState(true)
  const [value, setValue] = useState<Descendant[]>(prepareInitialValue(initialValue));
  const [editor] = useState(() => withReact(createEditor()));
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );
  const [charCount, setCharCount] = useState(0)

  useEffect(() => {
    if (onChange) {
      const serialized = serialize(value as SlateNode[])
      onChange(serialized === '<p></p>' ? '' : serialized);
    }
  }, [value, onChange]);


  useEffect(() => {
    setCharCount(isWordCount ? wordCount(value as SlateNode[]) : characterCount(value as SlateNode[]))
  }, [value, isWordCount]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (canEdit) {
      setKey(k => k + 1);
      setValue(prepareInitialValue(initialValue));
      setCanEdit(false)

      timeout = setTimeout(() => {
        setCanEdit(true)
      }, 1000)
    }

    return () => clearTimeout(timeout)
  }, [initialValue, canEdit]);

  return (
    <GrammarlyEditorPlugin
      clientId={"client_FHM1b345AmZdpuPwDHkmry"}
      config={{
        documentDialect: "british",
      }}
    >
      <Slate key={key} editor={editor} value={value} onChange={(v) => setValue(v)}>
        {/* Example - https://www.slatejs.org/examples/richtext */}
        <div className="flex flex-col sm:flex-row items-center mb-1">
          {label && <label className={`w-full sm:w-auto mb-2 sm:mb-0 ${labelClassName ? labelClassName : 'text-sm font-bold text-[#5F646D]'}`}>{label}</label>}
          {user?.role === 'ADMIN' && <div className="flex gap-1 ml-auto">
            <MarkButton format="bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M6.8 19V5h5.525q1.625 0 3 1T16.7 8.775q0 1.275-.575 1.963t-1.075.987q.625.275 1.388 1.025T17.2 15q0 2.225-1.625 3.113t-3.05.887H6.8Zm3.025-2.8h2.6q1.2 0 1.463-.613t.262-.887q0-.275-.263-.887T12.35 13.2H9.825v3Zm0-5.7h2.325q.825 0 1.2-.425t.375-.95q0-.6-.425-.975t-1.1-.375H9.825V10.5Z"
                />
              </svg>
            </MarkButton>

            <MarkButton format="italic">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M5 19v-2.5h4l3-9H8V5h10v2.5h-3.5l-3 9H15V19H5Z"
                />
              </svg>
            </MarkButton>

            <MarkButton format="underline">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M5 21v-2h14v2H5Zm7-4q-2.525 0-3.925-1.575t-1.4-4.175V3H9.25v8.4q0 1.4.7 2.275t2.05.875q1.35 0 2.05-.875t.7-2.275V3h2.575v8.25q0 2.6-1.4 4.175T12 17Z"
                />
              </svg>
            </MarkButton>

            <AddLinkButton />
            <RemoveLinkButton />
            <ListButton />
          </div>}
        </div>

        <Editable
          placeholder={placeholder}
          renderElement={props => <Element {...props} />}
          renderLeaf={renderLeaf}
          className={`border rounded-lg px-5 py-5 !min-h-[150px] text-cs-gray text-sm ${(maxChars && charCount > maxChars) ? 'border-cs-red' : 'border-[#CFDBD5]'}`}
          value={initialValue}
          onKeyDown={() => {
            onDirty?.()
          }}
          onPaste={() => {
            onDirty?.()
          }}
          onCut={() => {
            onDirty?.()
          }}
        />

        {maxChars && <p className="text-cs-gray text-sm flex w-full mt-2">
          <div className="text-sm text-cs-gray font-normal">{charLabel}</div>
          <div className="text-sm text-cs-gray font-normal ml-auto">{charCount + ' / ' + maxChars}{maxCharSuffix}</div>
        </p>}

        {(maxChars && (maxChars < charCount)) && <div className="text-cs-red flex items-center mt-2">
            <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
            <span className="flex-1">Text is too long</span>
        </div>}
      </Slate>
    </GrammarlyEditorPlugin>
  );
};

const toggleBlock = (editor: BaseEditor & ReactEditor, format: any) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => {
      return !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type)
    },
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      // @ts-ignore
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'text' : format,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    // const block = { type: 'bulleted-list', children: [] };
    // Transforms.setNodes(editor, block); // Set the block type to bulleted-list
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}
const isBlockActive = (editor: BaseEditor & ReactEditor, format: any, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        // @ts-ignore
        n[blockType] === format,
    })
  )

  return !!match
}

const toggleMark = (editor: BaseEditor & ReactEditor, format: Formats) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor: BaseEditor & ReactEditor, format: Formats) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Leaf = ({ attributes, children, leaf }: LeafProps) => {
  if (leaf.bold) {
    children = <strong className="font-bold [&>*]:font-bold">{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.link && leaf.href && leaf.href !== 'undefined') {
    children = <a href={leaf.href} className="text-primary underline">{children}</a>;
  }

  return <span {...attributes}>{children}</span>;
};

const MarkButton = ({
  format,
  children,
  className,
}: {
  format: Formats;
  children: ReactNode;
  className?: string;
}) => {
  const editor = useSlate();
  return (
    <button
      type="button"
      className={`p-1 transition rounded-sm ${className} ${
        isMarkActive(editor, format) && "bg-slate-200"
      }`}
      onMouseDown={() => {
        toggleMark(editor, format);
      }}
    >
      {children}
    </button>
  );
};

const deserialize = (
  el: HTMLElement,
  markAttributes: { bold?: boolean, italic?: boolean, underline?: boolean, link?: boolean, href?: string, list?: boolean } = {}
): object | string | null => {
  if (el.nodeType === Node.TEXT_NODE && !!el.textContent?.trim()) {
    return jsx("text", {...markAttributes }, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case "STRONG":
      nodeAttributes.bold = true;
      break;
    case "LI":
      nodeAttributes.list = true;
      break;  
    case "EM":
      nodeAttributes.italic = true;
      break;
    case "U":
      nodeAttributes.underline = true;
      break;
    case "A":
      nodeAttributes.link = true;
      nodeAttributes.href = el.getAttribute('href') || "#";
      break;  
  }

  const children = Array.from(el.childNodes)
    .map((node) => {
      const deserialized = deserialize(node as HTMLElement, nodeAttributes)
      return deserialized
    })
    .flat();

  if (children.filter(c => c !== null).length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return jsx(
        "text",
        {},
        [{text: '\n'}]
      );
    case "SPAN":
      return (!Array.isArray(children) || (Array.isArray(children) && children.length === 1)) ? jsx(
        "text",
        {},
        children
      ) : jsx(
        "element",
        { type: 'paragraph', grouped: true },
        children
      );
    case "SUP":
        return (!Array.isArray(children) || (Array.isArray(children) && children.length === 1)) ? jsx(
          "text",
          {},
          children
        ) : jsx(
          "element",
          { type: 'paragraph' },
          children
        );
    case "SUB":
        return (!Array.isArray(children) || (Array.isArray(children) && children.length === 1)) ? jsx(
          "text",
          {},
          children
        ) : jsx(
          "element",
          { type: 'paragraph' },
          children
        );      
    case "LI":
      return jsx(
        "element",
        { type: 'bulleted-list' },
        children
      );
    case "P":
      return jsx("element", { type: "paragraph" }, children);
    case "A":
      return jsx(
        "element",
        { type: "link", url: el.getAttribute("href") },
        children
      );
    case "STRONG":
      return children
    case "EM":
      return children
    case "U":
      return children  
    default:
      return jsx("element", { type: "paragraph" }, children);
  }
};

function hoistGrouped(obj: any): any {
  if (!obj.children) {
    return obj;
  }

  const updatedChildren = [];

  for (const child of obj.children) {
    if (child.grouped) {
      updatedChildren.push(child.children);
    } else {
      updatedChildren.push(hoistGrouped(child));
    }
  }

  return {
    ...obj,
    children: updatedChildren.flat(),
  };
}

function hoistGroupedChildrenRecursive(objs: any): any {
  return objs.map((obj: any)=> hoistGrouped(obj));
}


export const serialize = (node: SlateNode | SlateNode[]): string => {
  if (Array.isArray(node)) {
    return node.map((n) => serialize(n)).join("");
  }

  if (node.children) {
    return `<${(node as any).type === 'bulleted-list' ? 'li' : 'p'}>${node.children.map((c: SlateText | SlateNode) => {
      if (c.text) return serializeNode(c)
      if ((c as SlateNode).children) return (c as SlateNode).children.map((n) => serialize(n as SlateNode)).join("");
      return ""
    }).join('')}</${(node as any).type === 'bulleted-list' ? 'li' : 'p'}>`;
  }

  return serializeNode(node);
};

function convertSiblingFloatingTextNodesToParagraphs(item: any[]) {
  return item.map((child: any, index: any) => {
    if (
      child.text &&
      item.filter((c: any) => c.type === "paragraph" || c.type !== 'bulleted-list').length > 0
    ) {
      return { type: "paragraph", children: [{ text: child.text }] };
    }
    return child;
  })
}

function convertTextToParagraph(data: any, isRoot = false): any {
  if (Array.isArray(data) && isRoot) {
    return convertSiblingFloatingTextNodesToParagraphs(data);
  }

  return data.map((item: any) => {
    if ((item.type === "paragraph" || item.type === 'bulleted-list') && item.children) {
      const modifiedChildren = convertSiblingFloatingTextNodesToParagraphs(item.children);
      return { ...item, children: convertTextToParagraph(modifiedChildren) };
    }

    return item;
  });
}

const serializeNode = (node: CustomText) => {
  let output = node.text;

  if (node.italic) {
    output = "<em>" + output + "</em>";
  }

  if (node.underline) {
    output = "<u>" + output + "</u>";
  }

  if (node.bold) {
    output = "<strong>" + output + "</strong>";
  }

  if (node.link && node.href && node.href !== 'undefined') {
    output = `<a href="${node.href}">` + output + "</a>";
  }

  return output;
};

const characterCount = (node: SlateNode | SlateNode[]): number => {
  if (Array.isArray(node)) {
    return node.map((n) => characterCount(n)).reduce((a, b) => a + b, 0);
  }

  if (node.children) {
    return node.children.map((c: SlateText) => c.text).reduce((a, b) => a + (b?.length ?? 0), 0)
  }

  return node.text.length;
};

const wordCount = (node: SlateNode | SlateNode[]): number => {
  if (Array.isArray(node)) {
    return node.map((n) => wordCount(n)).reduce((a, b) => a + b, 0);
  }

  if (node.children) {
    return node.children.map((c: SlateText) => c.text).reduce((a, b) => a + (b === '' ? 0 : (b?.split(' ')?.length ?? 0)), 0)
  }

  return node.text.split(' ').length;
};


const prependParagraph = (rawInput: HTMLElement) => {
  const body = rawInput.innerHTML;

  if (!body.startsWith('<p') && !body.startsWith('<li')) {
    rawInput.innerHTML = `<p>${body}</p>`
  }

  return rawInput
}

export const prepareInitialValue = (input: string | undefined): Descendant[] => {
  const rawHTML = new DOMParser().parseFromString(
    input ?? "",
    "text/html"
  );
  const deserialized = convertTextToParagraph(hoistGroupedChildrenRecursive(deserialize(prependParagraph(rawHTML.body)) as Descendant[]), true);
  
  return input ? deserialized : [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]
}

export const getCharLength = (input: string) => {
  const serialized = prepareInitialValue(input);

  return characterCount(serialized as SlateNode[])
}

export const getWordLength = (input: string) => {
  const serialized = prepareInitialValue(input);

  return wordCount(serialized as SlateNode[])
}

export const shouldDirty = (initial: string | null | undefined, value: string) => {
  const isEmpty = [null, '', undefined].includes(initial) && ['', '<p></p>'].includes(value);
  return (initial ?? '') !== value && !Boolean(isEmpty)
}


const isLinkActive = (editor: BaseEditor & ReactEditor) => {
  return isMarkActive(editor, 'link')
}

const insertLink = (editor: BaseEditor & ReactEditor, url: string) => {
  try {
    if (url.startsWith('https://dayoutwiththekids.co.uk')) {
      url = url.replace('https://dayoutwiththekids.co.uk', 'https://www.dayoutwiththekids.co.uk')
    }
    if (
      !url.startsWith('/')
      && !url.startsWith('#')
      && !url.startsWith('https://www.dayoutwiththekids.co.uk/')
      && url !== ('https://www.dayoutwiththekids.co.uk') //handle homepage links
      && !url.startsWith('https://membership.dayoutwiththekids.co.uk') 
    ) { 
      throw new Error('Invalid Link. Only internal links are allowed.')
    }

  } catch (e: any) {
    alert(e?.message || 'Invalid Link. Only internal links are allowed.')
    return;
  }

  if (editor.selection) {
    Editor.addMark(editor, 'link', true);
    Editor.addMark(editor, 'href', url);

    Transforms.deselect(editor);
    Transforms.insertNodes(editor, {text: ' '});
    Editor.removeMark(editor, 'link');
    Editor.removeMark(editor, 'href');

    ReactEditor.deselect(editor);
  }
}
const unwrapLink = (editor: BaseEditor & ReactEditor) => {
  if (editor.selection) {
    const original = Editor.string(editor, editor.selection);
    Editor.removeMark(editor, 'link');
    Editor.removeMark(editor, 'href');

    Transforms.insertNodes(editor, {text: original});
    Editor.removeMark(editor, 'link');
    Editor.removeMark(editor, 'href');

    ReactEditor.deselect(editor);
  }
}
const AddLinkButton = () => {
  const editor = useSlate()
  return (
    <button
      type="button"
      disabled={isLinkActive(editor)}
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor, url)
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" viewBox="0 0 640 512"><path fill="currentColor" d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5l112.2-112.3c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0z"></path></svg>
    </button>
  )
}

const RemoveLinkButton = () => {
  const editor = useSlate()

  return (
    <button
      type="button"
      disabled={!isLinkActive(editor)}
      className={`${!isLinkActive(editor) ? 'opacity-50' : ''}`}
      onMouseDown={event => {
        event.preventDefault();
        if (isLinkActive(editor)) {
          unwrapLink(editor)
        }
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" viewBox="0 0 640 512"><path fill="currentColor" d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2s-6.3 25.5 4.1 33.7l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L489.3 358.2l90.5-90.5c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114l-96 96l-31.9-25c24.3-53.8 13.5-118.3-29.6-161.4c-52.2-52.3-134.5-56.2-191.3-11.7zM239 162c30.1-14.9 67.7-9.9 92.8 15.3c20 20 27.5 48.3 21.7 74.5zm167.6 254.4L220.9 270c-2.1 39.8 12.2 80.1 42.2 110c38.9 38.9 94.4 51 143.6 36.3zm-290-228.5l-56.4 56.4c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5l61.8-61.8l-50.6-39.9z"></path></svg>
    </button>
  )
}

const ListButton = () => {
  const editor = useSlate()
  const isActive = isBlockActive(
    editor,
    'bulleted-list',
    TEXT_ALIGN_TYPES.includes('bulleted-list') ? 'align' : 'type'
  );

  return (
    <button
      type="button"
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, 'bulleted-list')
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" viewBox="0 0 512 512"><path fill="currentColor" d="M40 48c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24zm152 16c-17.7 0-32 14.3-32 32s14.3 32 32 32h288c17.7 0 32-14.3 32-32s-14.3-32-32-32zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32h288c17.7 0 32-14.3 32-32s-14.3-32-32-32zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32h288c17.7 0 32-14.3 32-32s-14.3-32-32-32zM16 232v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24m24 136c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24z"></path></svg>
    </button>
  )
}



// // Put this at the start and end of an inline component to work around this Chromium bug:
// // https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
// const InlineChromiumBugfix = () => (
//   <span
//     contentEditable={false}
//     className={'font-[0]'}
//   >
//     {String.fromCodePoint(160) /* Non-breaking space */}
//   </span>
// )

const allowedSchemes = ['http:', 'https:', 'mailto:', 'tel:']
const LinkComponent = ({ attributes, children, element }: any) => {
  const selected = useSelected()

  const safeUrl = useMemo(() => {
    let parsedUrl: URL | null = null
    try {
      parsedUrl = new URL(element.url)
      // eslint-disable-next-line no-empty
    } catch {}
    if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol)) {
      return parsedUrl.href
    }
    return 'about:blank'
  }, [element.url])

  return (
    <a
      {...attributes}
      href={safeUrl}
      className={
        selected
          ? `shadow-sm text-primary underline`
          : ''
      }
    >
      {children}
    </a>
  )
}

const Element = (props: any) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'link':
      return <LinkComponent {...props} />
    case 'bulleted-list':
      return (
        <li className="list-disc [&>p]:inline" {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol className="list-decimal" {...attributes}>
          {children}
        </ol>
      )  
    default:
      return <p {...attributes}>{children}</p>
  }
}

export default RichTextEditor;
