import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import Header from "../../components/global/amp/Header";
import { SingleAttractionTemplate } from "../../components/global/SingleAttractionTemplate";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInput from "../../components/form/TextInput";
import { apiSlice } from "../../store/apiSlice";
import TownInput from "../../components/form/TownInput";
import { debounce } from "../../helpers/debounce";
import ImageUpload from "../../components/form/ImageUpload";
import { Button } from "../../components/global/Button";
import { PrimaryButton } from "../../components/global/PrimaryButton";
import { restartAnimation } from "../../components/global/amp/ToastNotification";
import { globalActions } from "../../store/globalSlice";
import { fetchFileData, fileToDataString } from "../../helpers/media";
import { mediaJsonSchema } from "./diaries/DiaryOverview";
import { ImageObject } from "../../../../client-core/src/types/blog";


interface EditUserForm {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    city: string;
    profile_picture: string;
    profile_picture_image: ImageObject
}

interface ForgottenPasswordForm {
    email: string;
}

const EditContentUser: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { user, toastNotification } = useAppSelector((state) => state.global);

    const schema = z.object({
        firstName: z.string().min(1, "First name is required"),
        lastName: z.string().min(1, "Last name is required"),
        phoneNumber: z
          .string()
          .optional()
          .refine(
            (val) =>
              !val ||
              new RegExp(
                /^(\+44\s?|\(0\d{4}\)\s?|\(0\d{3}\)\s?|\(0\d{2}\)\s?|\d{4}\s?|\d{3}\s?|\d{2}\s?)\d{3}\s?\d{4}(\s?\#\d{3,4})?$/
              ).test(val),
            "Invalid phone number. It should be at least 10 digits with no spaces"
        ),
        city: z.string().min(1, "City is required"),
        profile_picture: z.string().min(1, "Profile picture is required"),
        profile_picture_image: z.object(mediaJsonSchema).optional()
    });

    const {
        register,
        handleSubmit,
        formState: { isValid, isSubmitted, errors },
        control,
        getValues,
        setValue,
        resetField,
        watch,
        clearErrors
      } = useForm<EditUserForm>({
        resolver: async (data, context, options) => {
          return zodResolver(schema)(data, context, options);
        },
        defaultValues: {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          city: "",
          profile_picture: ""
        },
    });

    const [searchTown, {isLoading: isLoadingSearchTown}] = apiSlice.useLazySearchTownQuery();
    const [filteredCities, setFilteredCities] = useState<{name: string, priority: number}[]>([]);
    const cityValue = watch("city", "");
    const dispatch = useAppDispatch();

    const email = searchParams.get("email") ?? "";
    const { data: getUserByEmail, error: userError } = apiSlice.useGetUserByEmailQuery(email);
    const [
        updateUser,
        { isLoading, isError, isSuccess },
      ] = apiSlice.useUpdateUserMutation();
    
      const [requestPasswordReset, requestPasswordResetQueryData] =
        apiSlice.usePasswordResetRequestMutation();
      const {
        data,
        isError: isErrorResetPassword,
        isSuccess: isSuccessResetPassword,
        isLoading: isLoadingResetPassword,
      } = requestPasswordResetQueryData;

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleSearchTownData = useCallback(
        debounce(async (value: string) => {
          try {
            const result = await searchTown({ query: capitalizeFirstLetter(value) }).unwrap();
            if(result && result?.length){
                setFilteredCities(result);
            }else{
                setFilteredCities([]);
            }
          } catch (error) {
            console.error('Error on get town data:', error);
          }
        }, 300),
        [searchTown]
    );

    const handleCityInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if(inputValue){
            handleSearchTownData(inputValue);
        }else{
            setFilteredCities([]);
        }
        setValue('city', inputValue);
        clearErrors('city');
    };

    const onSubmit: SubmitHandler<EditUserForm> = async (e) => {
        if (!getUserByEmail?.auth0_id) {
          return
        }
        const payload = {
          first_name: getValues("firstName"),
          last_name: getValues("lastName"),
          email: getValues("email"),
          phone: getValues("phoneNumber"),
          city: getValues("city"),
          profile_picture: getValues("profile_picture")
        }
    
        await updateUser({
          id: getUserByEmail?.auth0_id,
          payload
        });
        restartAnimation();
    };
    

      const onSubmitResetPassword: SubmitHandler<ForgottenPasswordForm> = async (
        e
      ) => {
        if (getUserByEmail?.email) {
          await requestPasswordReset(getUserByEmail?.email);
          restartAnimation();
        }
      };

    useEffect(() => {
        if (getUserByEmail) {
          setValue("firstName", getUserByEmail?.first_name ?? "");
          setValue("lastName", getUserByEmail?.last_name ?? "");
          setValue("email", getUserByEmail?.email ?? "");
          setValue("phoneNumber", getUserByEmail?.phone ?? "");
          setValue("city", getUserByEmail?.city ?? "");
          setValue("profile_picture_image", getUserByEmail?.profile_picture_image);

          (async () => {
            if(getUserByEmail?.profile_picture_image){
              const mediaData = await fetchFileData(getUserByEmail.profile_picture_image.url);
              let  urlString = await fileToDataString(mediaData.data);
              setValue("profile_picture_image.data", urlString);
            }
          })()
          
        }
    }, [getUserByEmail]);

    useEffect(() => {
        isSuccess &&
          dispatch(
            globalActions.setToastNotifcation({
              ...toastNotification,
              type: "SUCCESS",
              message: "Your changes have been saved",
              attractionApprovalStatus: "",
              attractionImage: "",
              attractionName: "",
              attractionAddress: "",
            })
        );
    }, [isSuccess]);
    
    useEffect(() => {
        isError &&
          dispatch(
            globalActions.setToastNotifcation({
              ...toastNotification,
              type: "ERROR",
              message: "Cannot save changes",
              attractionApprovalStatus: "",
              attractionImage: "",
              attractionName: "",
              attractionAddress: "",
            })
        );
    }, [isError]);
    
    useEffect(() => {
        isSuccessResetPassword &&
          dispatch(
            globalActions.setToastNotifcation({
              ...toastNotification,
              type: "SUCCESS",
              message: data.message,
              attractionApprovalStatus: "",
              attractionImage: "",
              attractionName: "",
              attractionAddress: "",
            })
        );
    }, [isSuccessResetPassword]);
    
    useEffect(() => {
        isErrorResetPassword &&
          dispatch(
            globalActions.setToastNotifcation({
              ...toastNotification,
              type: "ERROR",
              message: "Cannot save changes",
              attractionApprovalStatus: "",
              attractionImage: "",
              attractionName: "",
              attractionAddress: "",
            })
        );
    }, [isErrorResetPassword]);

    return(
        <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
            <Header title="Edit User" showBack />
            <SingleAttractionTemplate name={"User Details"}>
                <h1 className="text-[22px] font-extrabold text-black flex items-center gap-2 mb-7">
                    <img src="/assets/www/person.png" alt="person"/>
                    {"View / Edit Family Creator User"}
                </h1>
                <form className="" onSubmit={handleSubmit(onSubmit)}>
                    <fieldset className="flex flex-wrap w-full mb-6 md:w-3/4 xl:w-2/5">
                        <div className="grid w-full grid-cols-2 gap-2 mb-3">
                            <TextInput
                                className="group"
                                labelClassName="group-focus-within:text-cs-pink font-bold"
                                inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                                control={control}
                                name={"firstName"}
                                label="First name"
                                required
                                inputProps={{ ...register("firstName") }}
                            />
                            <TextInput
                                className="group"
                                labelClassName="group-focus-within:text-cs-pink font-bold"
                                inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                                control={control}
                                name={"lastName"}
                                label="Last name"
                                required
                                inputProps={{ ...register("lastName") }}
                            />
                        </div>
                        <TextInput
                            className="w-full mb-3 group md:w-full"
                            labelClassName="group-focus-within:text-cs-pink font-bold"
                            inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                            control={control}
                            name={"email"}
                            label="Email address"
                            required
                            readonly={user?.role != "ADMIN" ? true : false}
                            inputProps={{ ...register("email") }}
                        />
                        <TextInput
                            className="w-full mb-3 group"
                            labelClassName="group-focus-within:text-cs-pink font-bold"
                            inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                            control={control}
                            name={"phoneNumber"}
                            label="Phone number"
                            inputProps={{ ...register("phoneNumber") }}
                        />
                        <div className="w-full">
                            <TownInput 
                                name="city" 
                                control={control} 
                                handleCityInputChange={handleCityInputChange} 
                                filteredCities={ cityValue ? filteredCities : []}
                                setValue={setValue}
                                clearErrors={()=> clearErrors("city")}
                                setFilteredCities={setFilteredCities}
                                label="Closest city"
                                className="flex flex-col w-full px-4 py-3 rounded-md h-fit border-cs-1 border-cs-bright-lt-gray text-cs-gray focus:outline-cs-pink"
                                isLoading={isLoadingSearchTown}
                            />
                        </div>
                        <div className="w-full mt-3 group">
                            <label
                            className={`${
                                errors?.profile_picture ? "text-cs-red" : "text-black"
                            } text-sm font-bold mb-1.5 block`}
                            >Profile picture  <span className="text-cs-pink"> *</span></label>
                            <ImageUpload 
                                control={control} 
                                clearErrors={()=> clearErrors("profile_picture_image")} 
                                name="profile_picture_image.data"
                                setValue={setValue}
                                className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.profile_picture ? "border-cs-red" : "border-black"} text-xs`}
                                imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                dropAreaImage={false}
                            />
                        </div>
                    </fieldset>
                    <div>
                        <PrimaryButton
                            className="w-[145px]"
                            scale="sm"
                            type="submit"
                            loading={isLoading}
                            >
                            Save
                        </PrimaryButton>
                    </div>
                </form>
                <form
                    className="w-full pt-6 xl:w-2/5 md:w-3/4"
                    onSubmit={handleSubmit(onSubmitResetPassword)}
                    >
                    <h3 className="text-[22px] font-extrabold text-black">Security</h3>
                    <p className="mt-2 mb-4 font-light text-[#5F646D] text-sm">Send the user instructions to reset their password</p>
                    <div className="w-[200px]">
                        <Button
                            color={"outlineGray"}
                            scale="md"
                            type="submit"
                            className="px-[18px]"
                            disabled={false}
                        >
                        {false ? "Resend Password" : "Reset Password"}
                        </Button>
                    </div>
                </form>
            </SingleAttractionTemplate>
        </div>
    )
}
  
export default EditContentUser;